<template>
  <!-- bind the default span's props -->
  <span :attrs="$attrs">
    {{ (this.total > 0 ? this.total : 0) | currency }}</span
  >
</template>

<script>
export default {
  name: "TotalWrapper",
  props: {
    total: {
      type: [Number, String],
      required: true
    }
  }
};
</script>
