<template>
  <v-card
    ref="form"
    tile
    elevation="0"
    color="white"
    fill-height
    class="text-center mx-auto justify-center align-center"
  >
    <div class="py-6 justify-center">
      <img src="@/assets/Local Bee orange.svg" width="40%" height="auto" />
    </div>
        <v-sheet v-if="!reset"
            color="grey lighten-4"
            class="mt-12 px-10"
            width="100%"
            height="100%"
            style="border-top-left-radius: 50px; border-top-right-radius: 50px;"
        >
          <h4 class="text-center justify-center font-weight-bold black--text py-14">
            {{this.$t('delivery.lang_forgotPassword')}}
          </h4>
          <v-form ref="resetPasswordForm" v-model="valid" @submit.prevent="resetPassword">
            <div>
              <div class="text-start text-left">
          <span class="grey--text text-start text-left caption">
            E-Mail <span class="error--text ">*</span>
          </span>
              </div>

              <v-text-field
                  ref="email"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  placeholder="name@example.com"
                  background-color="white"
                  append-icon="mdi-email-outline"
                  filled
                  dense
                  rounded
                  autocomplete="off"
                  class="rounded-lg"
              ></v-text-field>
            </div>
          </v-form>
          <div class="my-6">
            <v-btn
                large
                depressed
                block :loading="loading"
                color="primary" :disabled="!valid||loading"
                class="text-capitalize rounded-lg" @click="resetPassword"
            >
              {{$t("delivery.lang_continue")}}
            </v-btn>
          </div>
        </v-sheet>
        <v-sheet v-else
            color="grey lighten-4"
            class="mt-12 px-10"
            width="100%"
            height="100%"
            style="border-top-left-radius: 50px; border-top-right-radius: 50px;"
        >
          <h4 class="text-center justify-center font-weight-bold black--text py-14">
            {{this.$t('delivery.lang_forgotPassword')}}
          </h4>
          <v-form ref="resetPasswordForm" v-model="valid" @submit.prevent="changePassword">
            <v-row>
              <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  style="margin-top: -25px;"
              >
                <span class="grey--text text-start text-left caption">{{$t('delivery.lang_account_newPassword')}}<span class="error--text ">*</span></span>

                <v-text-field
                    ref="password"
                    v-model="password"
                    :append-icon="showHidePsw ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showHidePsw ? 'text' : 'password'"
                    name="password"
                    placeholder="*********"
                    outlined :rules="[rules.required,]"
                    dense
                    required
                    :hint="$t('delivery.lang_minChars8')" :loading="loadingChangePassword"
                    @click:append="showHidePsw = !showHidePsw"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  style="margin-top: -25px;"

              >
                <span class="grey--text text-start text-left caption">{{$t('delivery.lang_confirmPassword')}}<span class="error--text ">*</span></span>

                <v-text-field
                    ref="confirmPsw"
                    v-model="confirmPsw"
                    :append-icon="showHideConfirmPsw ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showHideConfirmPsw ? 'text' : 'password'"
                    name="confirmPsw"
                    placeholder="*********"
                    outlined :loading="loadingChangePassword"
                    dense
                    required :rules="[rules.required,v=>confirmPsw===password]"
                    @click:append="showHideConfirmPsw = !showHideConfirmPsw"
                ></v-text-field>
              </v-col>
            </v-row>

          </v-form>
          <div class="my-6">
            <v-btn
                large
                depressed
                block :loading="loadingChangePassword"
                color="primary" :disabled="!valid"
                class="text-capitalize rounded-lg" @click="changePassword"
            >
              {{$t("delivery.lang_continue")}}
            </v-btn>
          </div>
        </v-sheet>

  </v-card>
</template>

<script>
import ENDPOINTS from "../../plugins/axios/endpoints";

export default {
  name: "ForgotPassword",
  props:{
    reset:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      email: "",
      valid:false,
      loading:false,
      confirmPsw: "",
      showHidePsw: false,
      showHideConfirmPsw: false,
      loadingChangePassword: false,
      password: null,
      rules: {
        required: (value) => !!value ||this.$t("delivery.lang_required"),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("delivery.lang_invalidEmail");
        },
      },
    };
  },

  computed: {
    step: {
      get() {
        return this.$store.getters["Config/authDlg"];
      },
      set(val) {
        this.$store.commit("Config/setAuthDlg", val);
      },
    },
  },

  methods:{
    resetPassword(){
      if(!this.valid){
        return;
      }
      this.loading=true;

      this.axios.post(ENDPOINTS.ACCOUNT.RESETPASSWORD,{
        emailAddress:this.email
      }).then(res=>{
        if(res.status===200||res.status===201){
          this.$swal({
            title: "",
            icon: "success",
            text: this.$t('delivery.lang_checkYourEmailToVerifyResetYourPassword'),
          });
          this.$emit('close')
        }
      }).catch(err=>{
        this.$swal({
          title: this.$t('delivery.lang_error'),
          icon: "error",
          text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
        });
      }).finally(()=>{
        this.loading=false;
      })
    },

    changePassword(){
      this.loadingChangePassword=true;
      this.axios.post(ENDPOINTS.DELIVERY.RESETPASSWORD+this.$route.query.passwordToken,{
        password:this.password,
        password_again:this.confirmPsw,
      }).then(res=>{
        if(res.status===200||res.status===201){
          this.$swal({
            title: this.$t('delivery.lang_passwordResetSuccessfully'),
            icon: "success",
          });
          this.$emit('close');
          this.$router.replace('/');
        }else{
          this.$swal({
            title: res.data.message,
            icon: "error",
          });
        }
      }).catch(err=>{
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
        this.$swal({
          title: res.data.message,
          icon: "error",
        });

      }).finally(()=>{
        this.loadingChangePassword=false;
      })

    }
  }
};
</script>
