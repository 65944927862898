<template>
  <!--this component allow us to set/update items note-->
  <div>
    <v-dialog
      v-model="visible"
      class="mx-auto justify-center"
      :max-width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    >
      <v-card class="bg mx-auto justify-center">
        <!--start title icon-->
        <v-card-title class="text-center body-1 font-weight-bold">
          {{ this.selectedItem && this.selectedItem.name }}
        </v-card-title>
        <!--end title icon-->

        <!--note input-->
        <v-card-text class="pb-0 pt-2">
          <v-textarea
            v-model="itemNote"
            :label="$t('delivery.lang_typNoteHere')"
            dense
            outlined
            prepend-inner-icon="mdi-comment"
          />
        </v-card-text>

        <v-card-actions class="pt-0">
          <!--Cancel btn-->
          <v-btn @click="visible = false" text color="primary">
            {{ $t('delivery.lang_cancel') }}
          </v-btn>

          <v-spacer />

          <!--confirm btn-->
          <v-btn
            elevation="0"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="save"
          >
            {{ $t('delivery.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "ItemNoteDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item:Object,
  },

  data(){
    return{
      itemNote: "",
      loading:false,
    }
  },

  computed: {
    ...createNamespacedHelpers("Order").mapGetters(["selectedItem"]),

    /*using computed attr to avoid direct update of 'dialog' prop*/
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.note = "";
        this.$emit("input", val);
      },
    },
  },

  methods: {
    /*update item note*/
    save() {
      this.loading = true;


        this.$store.dispatch("Order/setItemFreetext", {
          id: this.selectedItem.id,
          freetext: this.itemNote,
        }).then(()=>{
          this.loading = false;
          this.visible = false;
        })

    },
  },
  watch: {
    dialog(val){
      if(val){
        this.itemNote=this.selectedItem.freetext;
      }
    }
  },
};
</script>
